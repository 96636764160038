function openNav() {
    document.getElementById("mySidenav").style.width = "75%";
   }

function closeNav() {
 document.getElementById("mySidenav").style.width = "0";
}


const hamburger = document.getElementById('mobileHam');
const closeMobile = document.getElementById('closeMobileHam');
const sideMenuKids = Array.from(document.getElementById('mySidenav').children);

console.log(sideMenuKids);
hamburger.addEventListener('click', openNav);
closeMobile.addEventListener('click', closeNav);
// alert('binod');


sideMenuKids.forEach(item => item.addEventListener('click', closeNav))